import SEO from "../SEO"
import { useRef } from "react"
import { Navigation } from "../ui/Navigation"
import { useStaticQuery, graphql } from "gatsby"
import { PricingPageWrapper } from "./styled"
import {
  OurServices,
  MoreGoodDays,
  OtherWaysToPay,
  HeroTitle,
} from "./sections"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { SuccessStories } from "../HomePage/sections"
import { FAQSection, InsuranceWidget, BigHero } from "../shared"
import Hero from "./assets/hero.webp"

const PRICING_PAGE_QUERY = graphql`
  query PricingPageQuery {
    allPrismicFaqWidget(filter: { data: { page_type: { eq: "pricing" } } }) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const PricingPageBody = (): JSX.Element => {
  const ourServicesRef = useRef<HTMLDivElement | null>(null)
  const response = useStaticQuery(PRICING_PAGE_QUERY)
  const faqs = response.allPrismicFaqWidget.edges[0].node.data.faqs

  const learnMoreClicked = () => {
    if (ourServicesRef.current) {
      const top = ourServicesRef.current.getBoundingClientRect().top
      window.scrollTo({ top: top + window.scrollY - 120, behavior: "smooth" })
    }
  }

  const heroDescription =
    "Personalized therapy and medication treatment plans designed to help you make real progress over time"
  return (
    <PricingPageWrapper>
      <BigHero
        Title={HeroTitle}
        image={Hero}
        learnMoreClicked={learnMoreClicked}
        description={heroDescription}
      />
      <OurServices ref={ourServicesRef} />
      <InsuranceWidget />
      <OtherWaysToPay />
      <SuccessStories />
      <MoreGoodDays />
      <FAQSection faqs={faqs} />
    </PricingPageWrapper>
  )
}

export const PricingPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Pricing"
        titleSuffix="Expert Online Therapy + Mental Health Care on Your Schedule"
        description="Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."
        url="pricing/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <PricingPageBody />
    </>
  )
}
