import { colors } from "../../themes/colors"
import styled, { keyframes } from "styled-components"

export const Wrapper = styled.section`
  display: flex;
  height: 600px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const LeftSide = styled.div`
  display: flex;
  padding: 24px;
  width: 50%;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const Header = styled.div`
  color: ${colors.c_primary[500]};
  font-family: Poppins;
  font-size: 64px;
  font-weight: 600;
  line-height: 80px;

  b {
    font-weight: 800;
    color: ${colors.c_primary[600]};
  }

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`
export const Text = styled.p`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  max-width: 320px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 16px;

  @media (max-width: 768px) {
    padding-top: 8px;
    max-width: none;
  }
`

export const HeroImage = styled.img`
  border-bottom-left-radius: 50px;
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    border-bottom-left-radius: 0px;
  }
`
export const RightSide = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const LearnMoreText = styled.p`
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const LearnMoreTextMobile = styled.p`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${colors.c_black[900]};
`
export const ArrowDown = styled.img`
  width: 12px;
  height: 12px;
  margin-top: 6px;
`
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
`

export const LearnMoreAnimation = styled.div`
  height: 96px;
  width: 96px;
  display: inline-flex;
  padding: 20px 25px 12px 25px;
  flex-direction: column;
  align-items: center;
  border-radius: 80px;
  background: ${colors.c_white[900]};
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  color: ${colors.c_primary[600]};
  animation: ${bounce} 400ms ease-in-out 600ms;
  svg {
    width: 24px;
    height: 24px;
  }
`

export const LearnMoreWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;
  cursor: pointer;

  @media (max-width: 768px) {
    bottom: 0px;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
  }
`

export const LearnMoreWrapperMobile = styled.div`
  text-align: center;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 320px;

  @media (max-width: 768px) {
    margin-top: 24px;
    width: 100%;
  }
`
